import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Login.scss";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleVerify = () => {
    const validUsername = "tiles";
    const validPassword = "granites";

    if (username === validUsername && password === validPassword) {
      navigate("/admin1413914");
      setError("");
    } else {
      setError("Invalid username or password. Please try again.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleVerify();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleVerify();
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="icon-container">
          <PersonIcon style={{ fontSize: 60, color: "#4285f4" }} />
        </div>
        <h2>Log in</h2>
        <p>Use your credentials to log in</p>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <PersonIcon className="input-icon" />
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={handleKeyPress}
              required
            />
          </div>
          <div className="input-group">
            <LockIcon className="input-icon" />
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
                required
              />
              <div
                className="password-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </div>
            </div>
          </div>
          <button type="submit" className="login-btn">
            Log In
          </button>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </form>
      </div>
    </div>
  );
};
