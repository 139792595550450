import React, { useState, useMemo, useCallback } from "react";
import "../Styles/Form.scss";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import data from "../Datas/Data";
const serverUrl = process.env.REACT_APP_SERVER_URL; // ${serverUrl}
const url = `${serverUrl}/uploads`;

export const Form = () => {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);
  const [formData, setFormData] = useState({
    modelNo: "",
    types: "",
    categories: "",
    productTitle: "",
    productDescription: "",
    size: "",
    brandName: "",
    youtubeLink: "",
    pricePerSqFt: "",
    images: [],
  });

  // Memoize category options to prevent recalculation on each render
  const categoriesOptions = useMemo(() => {
    return {
      Tiles: data.tiles,
      Granites: data.granites,
      SanitaryWares: data.sanitarywares,
    };
  }, []);

  // Handle input changes
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }, []);

  // Handle image uploads
  const handleFileUpload = useCallback((e) => {
    setFormData((prevData) => ({
      ...prevData,
      images: Array.from(e.target.files),
    }));
  }, []);

  // Delete selected image
  const handleImageDelete = useCallback((index) => {
    setFormData((prevData) => ({
      ...prevData,
      images: prevData.images.filter((_, i) => i !== index),
    }));
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    setIsUploading(true);
    e.preventDefault();
    const fd = new FormData();
    const currentDateTime = new Date().toISOString(); // Capture the current date and time

    Object.entries(formData).forEach(([key, value]) => {
      if (key === "images") {
        value.forEach((file) => fd.append("images", file));
      } else {
        fd.append(key, value);
      }
    });

    fd.append("createdAt", currentDateTime); // Add createdAt field to the form data

    try {
      const response = await axios.post(url, fd, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      console.log("Uploaded:", response.data);
      setIsUploading(false);
      setFormData({
        modelNo: "",
        types: "",
        categories: "",
        productTitle: "",
        productDescription: "",
        size: "",
        brandName: "",
        youtubeLink: "",
        pricePerSqFt: "",
        images: [],
      });
    } catch (error) {
      console.error("Error uploading:", error);
    }
  };

  // Render category options based on selected type
  const renderCategoryOptions = () => {
    return categoriesOptions[formData.types]?.map((category) => (
      <div className="radio" key={category}>
        <input
          type="radio"
          name="categories"
          value={category}
          checked={formData.categories === category}
          onChange={handleChange}
        />
        <span
          onClick={() => setFormData({ ...formData, categories: category })}
        >
          {category}
        </span>
      </div>
    ));
  };

  return (
    <div className="form">
      <h3>Create Listing</h3>

      <form onSubmit={handleSubmit}>
        <div className="box">
          <label>Design Name:</label>
          <input
            required
            type="text"
            name="modelNo"
            value={formData.modelNo}
            onChange={handleChange}
          />
        </div>
        <div className="box">
          <label>Type:</label>
          <select name="types" value={formData.types} onChange={handleChange}>
            <option value="">Select Type</option>
            {Object.keys(categoriesOptions).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>
        {formData.types && (
          <div className="radio_box">
            <p>Select the Category:</p>
            <div className="radioBox">{renderCategoryOptions()}</div>
          </div>
        )}
        <div className="image_box">
          <label htmlFor="image_upload" className="upload-label">
            <CloudUploadOutlinedIcon style={{ fontSize: 40, color: "#333" }} />
            <p>Upload Images</p>
          </label>
          <input
            id="image_upload"
            type="file"
            accept=".jpeg, .png, .jpg"
            multiple
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </div>
        {formData.images.length > 0 && (
          <div className="image-previews">
            <h4>Selected Images:</h4>
            <div className="image-list">
              {formData.images.map((file, index) => (
                <div key={index} className="image-item">
                  <img
                    height="100px"
                    src={URL.createObjectURL(file)}
                    alt={`preview-${index}`}
                    className="image-preview"
                  />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(index)}
                    className="delete_button"
                  >
                    <DeleteIcon className="delete_icon" sx={{ fontSize: 40 }} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="box lb">
          <label>Product Title:</label>
          <input
            type="text"
            name="productTitle"
            value={formData.productTitle}
            onChange={handleChange}
          />
        </div>
        <div className="box pd">
          <label>Product Description:</label>
          <textarea
            rows="4"
            cols="50"
            name="productDescription"
            value={formData.productDescription}
            onChange={handleChange}
          ></textarea>
        </div>
        {(formData.types === "Tiles" || formData.types === "SanitaryWares") && (
          <div className="box">
            <label>Size:</label>
            <input
              type="text"
              name="size"
              value={formData.size}
              onChange={handleChange}
            />
          </div>
        )}
        <div className="necessary">
          <div className="box">
            <label>Brand Name:</label>
            <input
              type="text"
              name="brandName"
              value={formData.brandName}
              onChange={handleChange}
            />
          </div>
          <div className="box">
            <label>
              Price {formData.types !== "SanitaryWares" && "per sq ft"}:
            </label>
            <div className="price">
              <span>₹ </span>
              <input
                type="number"
                name="pricePerSqFt"
                value={formData.pricePerSqFt}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="box lb">
          <label>YouTube Link:</label>
          <input
            type="text"
            name="youtubeLink"
            value={formData.youtubeLink}
            onChange={handleChange}
          />
        </div>
        <div className="btn">
          <button type="submit" disabled={isUploading}>
            {isUploading ? "Creating..." : "Create"}
          </button>
        </div>
      </form>
    </div>
  );
};
