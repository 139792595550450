import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LandscapeOutlinedIcon from "@mui/icons-material/LandscapeOutlined";
import TextureIcon from "@mui/icons-material/Texture";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "../Styles/Nav_Container.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import img from "../docs/logo-tiles_company.jpg";
import { Category, GradeRounded } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import { Filter } from "./Filter";
import CloseIcon from "@mui/icons-material/Close";

const navItems = [
  { to: "/", label: "Home", icon: <HomeOutlinedIcon /> },
  { to: "/Tiles", label: "Tiles", icon: <GridViewOutlinedIcon /> },
  { to: "/Granites", label: "Granites", icon: <LandscapeOutlinedIcon /> },
  {
    to: "/SanitaryWares",
    label: "Sanitary Wares",
    icon: <ShowerOutlinedIcon />,
  },
];

export const Nav_Container = () => {
  const location = useLocation();
  const { type } = useParams();
  const [currentNav, setCurrentNav] = useState("");
  const isAdminRoute = location.pathname === "/admin";
  const [isModalOpen, setModalOpen] = useState(false);

  // Function to handle modal open
  const handleModalOpen = () => {
    setCurrentNav("model");
    setModalOpen(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setCurrentNav("home");
    }
  }, [location.pathname, type]);

  const handleNavClick = (val) => {
    setCurrentNav(val);
  };

  return (
    <div className="nav">
      <div className="logo">
        <img src={img} alt="Company Logo" />
      </div>
      <div className="menu">
        {navItems.map(({ to, label, icon }) => (
          <Link
            key={to}
            style={{ textDecoration: "none", width: "95%" }}
            to={to}
          >
            <div
              onClick={() => handleNavClick(label.toLowerCase())}
              style={{
                backgroundColor:
                  currentNav === label.toLowerCase() ? "#ec1b22" : null,
                fontWeight: currentNav === label.toLowerCase() ? "700" : "500",
                color: currentNav === label.toLowerCase() ? "white" : "black",
              }}
            >
              {React.cloneElement(icon, {
                style: {
                  fontSize:
                    currentNav === label.toLowerCase() ? "25px" : "21px",
                  color: currentNav === label.toLowerCase() ? "white" : "black",
                },
              })}
              <p>{label}</p>
            </div>
          </Link>
        ))}
      </div>
      <div className="mobile_menu">
        {navItems.map(({ to, label, icon }) => (
          <Link
            key={to}
            style={{ textDecoration: "none", width: "95%" }}
            to={to}
          >
            <div
              className="nav_icons"
              onClick={() => handleNavClick(label.toLowerCase())}
              style={{
                fontWeight: currentNav === label.toLowerCase() ? "700" : "700",
                color: currentNav === label.toLowerCase() ? "#ec1b22" : "black",
              }}
            >
              {React.cloneElement(icon, {
                className: "navicon",
                style: {
                  fontSize:
                    currentNav === label.toLowerCase() ? "30px" : "29px",
                  color:
                    currentNav === label.toLowerCase() ? "#ec1b22" : "black",
                },
              })}
            </div>
          </Link>
        ))}
        <Link style={{ textDecoration: "none", width: "95%" }}>
          <div
            className="nav_icons"
            onClick={() => handleModalOpen()}
            style={{
              fontWeight: currentNav === "modal" ? "700" : "700",
              color: currentNav === "modal" ? "#ec1b22" : "black",
            }}
          >
            <FilterListIcon
              className="navicon"
              style={{
                fontSize: currentNav === "modal" ? "30px" : "29px",
                color: currentNav === "modal" ? "#ec1b22" : "black",
              }}
            />
          </div>
        </Link>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose} // Close modal when clicking outside or close button
      >
        <div
          style={{
            background: "transparent",
            width: "100%",
          }}
        >
          <div
            className="close_icon"
            style={{
              height: "9%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              right: "20px",
              top: "0",
              zIndex: "300",
              color: "#3f51b5",
              fontSize: "24px",
            }}
          >
            <CloseIcon onClick={handleModalClose} />
          </div>
          <Filter />
        </div>
      </Modal>
      <div className="extra">
        {/* <Link style={{ textDecoration: "none", width: "95%" }} to={`/about`}>
          <div
            className="ab"
            onClick={() => handleNavClick("about")}
            style={{
              backgroundColor: currentNav === "about" ? "#ec1b22" : null,
              fontWeight: currentNav === "about" ? "700" : "500",
              color: currentNav === "about" ? "white" : "black",
            }}
          >
            <InfoOutlinedIcon
              style={{
                // fontSize: 21,
                fontSize: currentNav === "about" ? "25" : "21",
                color: currentNav === "about" ? "white" : "black",
              }}
            />
            <p>About</p>
          </div>
        </Link> */}
        <div style={{ fontSize: "15px" }}>
          <PhoneIcon style={{ fontSize: 20, color: "black" }} />
          <p style={{ fontWeight: "500" }}>+91 8903304612</p>
        </div>
      </div>
      <div
        className="whatsapp"
        style={{
          display: !isAdminRoute ? "flex" : "none",
        }}
      >
        <a href="http://Wa.me/+918903304612">
          <IconButton color="success">
            <WhatsAppIcon sx={{ fontSize: 40, color: "green" }} />
          </IconButton>
        </a>
      </div>
    </div>
  );
};
