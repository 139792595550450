import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Feed } from "./Components/Feed";
import { Filter } from "./Containers/Filter";
import { Nav_Container } from "./Containers/Nav_Container";
import { Admin } from "./Components/Admin";
import { Home } from "./Pages/Home";
import { About } from "./Components/About";
import { Login } from "./Pages/Login";
import { PriceRangeProvider } from "./context/PriceRangeContext";
import ProductDetails from "./Pages/ProductDetails";
import Edit from "./Pages/Edit";

// Layout Component
const Layout = ({ children, priceRange, onPriceRangeChange }) => {
  const location = useLocation();
  const isSpecialRoute =
    location.pathname === "/admin" || location.pathname === "/login";

  return (
    <div className="App">
      <Nav_Container />
      <div
        style={{
          width: isSpecialRoute ? "80%" : "60%",
        }}
        className="mid_part"
      >
        {children}
      </div>
      {!isSpecialRoute && (
        <div className="filter_part">
          <Filter />
        </div>
      )}
      {/* Show filter only if not special route */}
    </div>
  );
};

// Main App Component
const App = () => {
  return (
    <PriceRangeProvider>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:type" element={<Feed />} />
            <Route path="/productDetails/:id" element={<ProductDetails />} />
            <Route path="/admin1413914" element={<Admin />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/editProduct/:id" element={<Edit />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </PriceRangeProvider>
  );
};

export default App;
