import React, { useContext, useState } from "react";
import "../Styles/Filter.scss";
import FilterListIcon from "@mui/icons-material/FilterList";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox"; // Import Checkbox from Material UI
import FormControlLabel from "@mui/material/FormControlLabel";
import { PriceRangeContext } from "../context/PriceRangeContext";

export const Filter = () => {
  const { range, setRange, tempRange, setTempRange } =
    useContext(PriceRangeContext);

  // State for price per square foot and area
  const [pricePerSqFt, setPricePerSqFt] = useState(0);
  const [areaInSqFt, setAreaInSqFt] = useState(0);
  const [isFilterApplied, setIsFilterApplied] = useState(false); // State to track checkbox
  const [lastAppliedRange, setLastAppliedRange] = useState(tempRange); // Store last applied range

  const handleMinChange = (e) => {
    const value = Math.max(0, Number(e.target.value)); // Ensures minimum price doesn't go below 0
    setTempRange([value, tempRange[1]]);
  };

  const handleMaxChange = (e) => {
    const value = Math.min(20000, Number(e.target.value)); // Ensures maximum price doesn't exceed 20000
    setTempRange([tempRange[0], value]);
  };

  const applyFilter = () => {
    // Apply the current tempRange and check the checkbox
    setRange(tempRange); // Update context with new range
    setIsFilterApplied(true);
    setLastAppliedRange(tempRange); // Store the last applied range for future use
    console.log(`Applied Price Range: ₹${tempRange[0]} - ₹${tempRange[1]}`);
  };

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsFilterApplied(isChecked); // Update checkbox state

    if (isChecked) {
      // If the checkbox is checked, apply the last applied range again
      setRange(lastAppliedRange);
      console.log(
        `Reapplied Price Range: ₹${lastAppliedRange[0]} - ₹${lastAppliedRange[1]}`
      );
    } else {
      // If unchecked, reset the range
      setRange([0, 20000]);
      console.log("Filter is disabled and range is reset.");
    }
  };

  return (
    <div className="filter">
      <div className="head">
        <FilterListIcon />
        <span>Filter by Price</span>
      </div>
      <div className="body">
        <div className="upper">
          <Slider
            className="slider"
            value={tempRange}
            valueLabelDisplay="auto"
            min={0}
            max={20000}
            aria-readonly="true" // Makes the slider read-only
          />

          {/* Input fields for typing precise price values */}
          <div className="price-inputs">
            <div className="input-group">
              <label>Min Price (₹):</label>
              <input
                type="number"
                value={tempRange[0]}
                onChange={handleMinChange}
                min="0"
                max="20000"
              />
            </div>
            <div className="input-group">
              <label>Max Price (₹):</label>
              <input
                type="number"
                value={tempRange[1]}
                onChange={handleMaxChange}
                min="0"
                max="20000"
              />
            </div>
          </div>

          <Button
            className="set_btn"
            variant="contained"
            color="primary"
            onClick={applyFilter}
            style={{ marginTop: "10px" }}
          >
            Set Filter
          </Button>
        </div>

        {/* Checkbox for applied range */}
        <div className="applied-range">
          <FormControlLabel
            className="check"
            control={
              <Checkbox
                checked={isFilterApplied}
                onChange={handleCheckboxChange}
                color="primary"
                style={{
                  transform: "scale(1.2)",
                }} // Scale the checkbox size for better visibility
              />
            }
            label={
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "13px",
                  marginLeft: "2px",
                  color: "black",
                }}
              >
                Applied Range: ₹{range[0]} - ₹{range[1]}
              </span>
            }
          />
        </div>

        {/* Price Calculator */}
        <div className="calculator">
          <h4>Price Calculator</h4>
          <div className="input-group">
            <label>Price per Sq Ft (₹):</label>
            <input
              type="number"
              placeholder="Enter price per sq ft"
              onChange={(e) => setPricePerSqFt(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Area in Sq Ft:</label>
            <input
              type="number"
              placeholder="Enter area in sq ft"
              onChange={(e) => setAreaInSqFt(e.target.value)}
            />
          </div>
          <div className="total-price">
            Total Price: ₹{pricePerSqFt * areaInSqFt || 0}
          </div>
        </div>
      </div>
    </div>
  );
};
