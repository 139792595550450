import React, { createContext, useState } from "react";

// Create the context
export const PriceRangeContext = createContext();

// Create the provider component
export const PriceRangeProvider = ({ children }) => {
  const [range, setRange] = useState([0, 20000]); // Default price range
  const [tempRange, setTempRange] = useState([0, 20000]); // Temporary range for slider
  const [version, setVersion] = useState(0); // Version state to track changes

  // Function to set range and update version
  const applyRange = (newRange) => {
    setRange(newRange);
    setVersion((prev) => prev + 1); // Increment version to trigger re-renders
  };

  return (
    <PriceRangeContext.Provider
      value={{ range, setRange: applyRange, tempRange, setTempRange, version }}
    >
      {children}
    </PriceRangeContext.Provider>
  );
};
