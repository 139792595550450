import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Styles/AdminHome.scss";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export const AdminHome = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL; // ${serverUrl}
  const [searchModelNo, setSearchModelNo] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [recentItems, setRecentItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch listings on component mount
  useEffect(() => {
    fetchListings();
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      searchByModelNo();
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await axios.delete(`${serverUrl}/api/delete/${id}`);
        // Refresh the list or remove the deleted item from the UI
        fetchListings(); // Refresh the list after deletion
      } catch (error) {
        console.error("Error deleting the item", error);
      }
    }
  };

  // Fetch all listings from the server
  const fetchListings = async () => {
    setLoading(true);
    setError(null); // Clear any previous errors
    try {
      const response = await axios.get(`${serverUrl}/api/listings`);
      setRecentItems(response.data.slice(0, 3)); // Show top 4 recent items
    } catch (error) {
      setError("Error fetching listings");
    } finally {
      setLoading(false);
    }
  };

  // Handle search input change
  const handleSearchInputChange = (e) => {
    setSearchModelNo(e.target.value);
  };

  // Search by modelNo
  const searchByModelNo = async () => {
    setLoading(true);
    setError(null); // Clear previous error
    try {
      const response = await axios.get(
        `${serverUrl}/api/admin_search?modelNo=${searchModelNo}`
      );
      if (response.data.length === 0) {
        setError("No results found");
      } else {
        setSearchResults(response.data);
      }
    } catch (error) {
      setError("No results found or an error occurred");
    } finally {
      setLoading(false);
    }
  };

  // Render recent items as cards
  const renderRecentItems = () => {
    return (
      <div className="results-container">
        {recentItems.map((result, index) => (
          <Card
            onClick={() => navigate(`/productDetails/${result._id}`)}
            key={index}
            className="card"
          >
            <Box>
              <Carousel showThumbs={false} showArrows={false}>
                {result.imageUrls.map((url, i) => (
                  <div key={i}>
                    <img
                      src={url}
                      alt={`Product Image ${i + 1}`}
                      className="card-image"
                    />
                  </div>
                ))}
              </Carousel>
              <IconButton
                className="edit_icon"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/editProduct/${result._id}`);
                }}
              >
                <EditIcon className="edit" />
              </IconButton>
            </Box>
            <CardContent className="contents">
              <Typography gutterBottom variant="h6">
                {result.productTitle}
              </Typography>
              <Typography variant="h6" color="primary">
                ₹{result.pricePerSqFt}
              </Typography>
            </CardContent>
            <CardActions className="card-actions">
              <Button size="small" className="card-button">
                View Details
              </Button>

              <Button
                size="small"
                color="secondary"
                className="delete_icon"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent triggering the card's onClick
                  handleDelete(result._id); // Function to handle deletion
                }}
              >
                <DeleteIcon className="delete" />
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <div className="admin-home">
      {/* Search Bar */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by Model No."
          value={searchModelNo}
          onChange={handleSearchInputChange}
          onKeyPress={handleKeyPress}
        />
        <button onClick={searchByModelNo}>Search</button>
      </div>

      {/* Search Results */}
      <div className="search-results">
        {error ? (
          <p>{error}</p>
        ) : (
          searchResults.length > 0 &&
          searchResults.map((result, index) => (
            <Card
              onClick={() => navigate(`/productDetails/${result._id}`)}
              key={index}
              className="card"
            >
              <Box>
                <Carousel showThumbs={false} showArrows={false}>
                  {result.imageUrls.map((url, i) => (
                    <div key={i}>
                      <img
                        src={url}
                        alt={`Product Image ${i + 1}`}
                        className="card-image"
                      />
                    </div>
                  ))}
                </Carousel>
                <IconButton
                  className="edit_icon"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/editProduct/${result._id}`);
                  }}
                >
                  <EditIcon className="edit" />
                </IconButton>
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  {result.productTitle}
                </Typography>
                <Typography variant="h6" color="primary">
                  ₹{result.pricePerSqFt}
                </Typography>
              </CardContent>
              <CardActions className="card-actions">
                <Button size="small" className="card-button">
                  View Details
                </Button>

                <Button
                  size="small"
                  color="secondary"
                  className="delete_icon"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the card's onClick
                    handleDelete(result._id); // Function to handle deletion
                  }}
                >
                  <DeleteIcon className="delete" />
                </Button>
              </CardActions>
            </Card>
          ))
        )}
      </div>
      {/* Recently Created Items */}
      <h3>Recently Created Items</h3>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {recentItems.length > 0 ? (
            renderRecentItems()
          ) : (
            <p>No recent items available</p>
          )}
        </>
      )}
    </div>
  );
};
