import React from "react";
import "../Styles/Footer.scss";
import img from "../docs/logo-tiles_company.jpg";
import { Email, Phone, SimCard, LocationOn } from "@mui/icons-material";
import {
  Twitter,
  Facebook,
  LinkedIn,
  Instagram,
  YouTube,
  WhatsApp,
} from "@mui/icons-material";

export const Footer = () => {
  return (
    <div className="foot">
      {" "}
      <footer>
        <div className="footerarea">
          <div className="area">
            <div className="f1">
              <a href="https://www.francisxavier.ac.in/">
                <div className="image_div">
                  <img src={img} alt="Logo" />
                </div>
              </a>
              <p>
                Our Tiles & Granite shop was established in the year 2024 and is
                renowned as one of the leading suppliers of premium tiles and
                granite in Tirunelveli, Southern Tamil Nadu, India. The shop is
                situated in the heart of Tirunelveli, a city known for its rich
                architectural heritage and craftsmanship.
              </p>
            </div>

            <div className="f3">
              <h3>Contact Us</h3>
              <p className="spann">+91 9698258635</p>
              <p className="footer_p">
                <Phone style={{ fontSize: "16px" }} />
                +91 8903304612
              </p>
              <p className="footer_p">
                <Email style={{ fontSize: "16px" }} />
                sales@arockiaannaitiles.com
              </p>
            </div>

            <div className="f4">
              <h3>Address</h3>
              <p className="location_p pp">
                <LocationOn style={{ fontSize: "16px" }} />
                Arockia Annai Tiles & Granites,
              </p>
              <p className="pp">8/163/137,</p>
              <p className="pp">Pudukottai Main Road,</p>
              <p className="pp">Kootampuli, Thoothukudi,</p>
              <p className="pp">Tamilnadu-628103 India.</p>
            </div>
          </div>

          <div className="copy">
            <p>
              Copyright © 2024, All Rights Reserved by{" "}
              <a className="copy_para" href="/">
                Arockia Annai Tiles & Granites
              </a>
            </p>
            <ul>
              <li>
                <a href="#">
                  <Twitter style={{ fontSize: "20px" }} />
                </a>
              </li>
              <li>
                <a href="#">
                  <Facebook style={{ fontSize: "20px" }} />
                </a>
              </li>
              <li>
                <a href="#">
                  <LinkedIn style={{ fontSize: "20px" }} />
                </a>
              </li>
              <li>
                <a href="#">
                  <Instagram style={{ fontSize: "20px" }} />
                </a>
              </li>
              <li>
                <a href="#">
                  <YouTube style={{ fontSize: "20px" }} />
                </a>
              </li>
              <li>
                <a href="#">
                  <WhatsApp style={{ fontSize: "20px" }} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};
