import React, { useState } from "react";
import "../Styles/Main_Container.scss";
import { Link, useLocation } from "react-router-dom";
import { Person } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import img from "../docs/final_logo.jpg";

export const Main_Container = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const isAdminRoute = location.pathname === "/admin";

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = () => {
    onSearch(searchTerm); // Trigger search with the current search term
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(); // Trigger search on Enter key press
    }
  };

  return (
    <div className="main">
      <header>
        <div className="logo">
          <img src={img} alt="" />
        </div>
        <div className="inp">
          <input
            placeholder={
              isAdminRoute
                ? "You can't use this for this page..."
                : "Search...."
            }
            type="text"
            className={isAdminRoute ? "disabled-input" : "none"}
            value={searchTerm}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          {/* <SearchIcon
            onClick={handleSearch}
            style={{ fontSize: "28px", cursor: "pointer" }}
          /> */}
          <SearchIcon
            onClick={!isAdminRoute ? handleSearch : null}
            style={{
              fontSize: "28px",
              cursor: !isAdminRoute ? "pointer" : "not-allowed",
              opacity: !isAdminRoute ? 1 : 0.5,
            }}
          />
        </div>
        <div
          className="admin_style"
          style={{
            display: !isAdminRoute ? "flex" : "none",
          }}
        >
          <Link to={`/login`}>
            <button className="navbar_right_account">
              <Person sx={{ color: "#969393" }} />
            </button>
          </Link>
        </div>
      </header>
    </div>
  );
};
