const data = {
  tiles: [
    "Glossy",
    "Matt",
    "Kitchen",
    "Digital",
    "Bathroom",
    "Parking",
    "Elevation",
    "Carvings",
    "Glossy elevation",
    "High depth elevation",
    "Picture",
    "High glossy",
    "Super high glossy",
  ],
  granites: [
    "Black Granites",
    "White Granites",
    "Red Granites",
    "Blue Granites",
  ],
  sanitarywares: [
    "Shower baths",
    "Stainless Steel",
    "Toilet articles",
    "Article of json & steel",
    "C.P.Fiting",
  ],
};

export default data;
