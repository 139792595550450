import React, { useState } from "react";
import "../Styles/Admin.scss";
import { Form } from "./Form";
import { Main_Container } from "../Containers/Main_Container";
import { AdminHome } from "./AdminHome";
import { Footer } from "./Footer";

export const Admin = () => {
  const [select, setSelect] = useState("home");

  const handleClick = (val) => {
    setSelect(val);
  };

  return (
    <div className="admin">
      <Main_Container />
      <section>
        <div
          className="select"
          onClick={() => {
            handleClick("home");
          }}
          style={{
            borderBottom: select === "home" ? "4px solid #ec1b22" : "none",
          }}
        >
          Home
        </div>
        <div
          className="select"
          onClick={() => {
            handleClick("create");
          }}
          style={{
            borderBottom: select === "create" ? "4px solid #ec1b22" : "none",
          }}
        >
          Create Listing
        </div>
      </section>
      <div className="mid">
        {select === "create" && <Form />}
        {select === "home" && (
          <div className="admin-home">
            <AdminHome />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
