import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Styles/Form.scss";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
const serverUrl = process.env.REACT_APP_SERVER_URL; // ${serverUrl}
const productUrl = `${serverUrl}/api/productDetails`; // Your API URL

const Edit = () => {
  const { id } = useParams(); // Get product ID from URL
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    modelNo: "",
    types: "",
    categories: "",
    productTitle: "",
    productDescription: "",
    size: "",
    brandName: "",
    youtubeLink: "",
    pricePerSqFt: "",
    images: [], // Existing images
    newImages: [], // Newly uploaded images
  });

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    // Fetch product data when component mounts
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${productUrl}/${id}`);
        const product = response.data;
        setFormData({
          modelNo: product.modelNo,
          types: product.types,
          categories: product.categories,
          productTitle: product.productTitle,
          productDescription: product.productDescription,
          size: product.size,
          brandName: product.brandName,
          youtubeLink: product.youtubeLink,
          pricePerSqFt: product.pricePerSqFt,
          images: product.imageUrls || [], // Existing images
          newImages: [], // Initialize newImages as empty
        });
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };
    fetchProduct();
  }, [id]);

  const handleSubmit = async (e) => {
    setIsUploading(true);
    e.preventDefault();

    if (!formData.modelNo || !formData.productTitle) {
      alert("Please fill all the required fields.");
      return;
    }

    const fd = new FormData();

    // Add form data except for images
    Object.entries(formData).forEach(([key, value]) => {
      if (key !== "newImages" && key !== "images") {
        fd.append(key, value);
      }
    });

    // Handle new images (files)
    formData.newImages.forEach((file) => fd.append("images", file));

    // Handle existing images (URLs)
    formData.images.forEach((url, index) => {
      fd.append(`existingImages[${index}]`, url); // Each URL as a separate form field
    });

    try {
      // Send the updated data to the backend
      await axios.put(`${productUrl}/${id}`, fd, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      navigate("/admin1413914"); // Navigate back to home or product list after update
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    setFormData({
      ...formData,
      newImages: [...formData.newImages, ...newFiles],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageDelete = (index, type) => {
    if (type === "existing") {
      const updatedImages = formData.images.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        images: updatedImages.length ? updatedImages : [], // Ensure empty array when all deleted
      });
    } else {
      const updatedNewImages = formData.newImages.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        newImages: updatedNewImages.length ? updatedNewImages : [], // Ensure empty array when all deleted
      });
    }
  };

  return (
    <div className="form">
      <h3>Edit Product</h3>

      <form onSubmit={handleSubmit}>
        <div className="box">
          <label>Design Name:</label>
          <input
            type="text"
            name="modelNo"
            value={formData.modelNo}
            onChange={handleChange}
            required
          />
        </div>
        <div className="box">
          <label>Type:</label>
          <select
            name="types"
            value={formData.types}
            onChange={handleChange}
            required
          >
            <option value="">Select Type</option>
            <option value="Tiles">Tiles</option>
            <option value="Granites">Granites</option>
            <option value="SanitaryWares">SanitaryWares</option>
          </select>
        </div>
        <div className="box">
          <label>Categories:</label>
          <input
            type="text"
            name="categories"
            value={formData.categories}
            onChange={handleChange}
          />
        </div>
        <div className="box">
          <label>Product Title:</label>
          <input
            type="text"
            name="productTitle"
            value={formData.productTitle}
            onChange={handleChange}
            required
          />
        </div>
        <div className="box">
          <label>Product Description:</label>
          <textarea
            name="productDescription"
            value={formData.productDescription}
            onChange={handleChange}
          />
        </div>
        <div className="box">
          <label>Size:</label>
          <input
            type="text"
            name="size"
            value={formData.size}
            onChange={handleChange}
          />
        </div>
        <div className="box">
          <label>Brand Name:</label>
          <input
            type="text"
            name="brandName"
            value={formData.brandName}
            onChange={handleChange}
          />
        </div>
        <div className="box">
          <label>YouTube Link:</label>
          <input
            type="text"
            name="youtubeLink"
            value={formData.youtubeLink}
            onChange={handleChange}
          />
        </div>
        <div className="box">
          <label>Price Per SqFt:</label>
          <input
            type="number"
            name="pricePerSqFt"
            value={formData.pricePerSqFt}
            onChange={handleChange}
          />
        </div>

        {/* Image upload and display */}
        <div className="image_box">
          <label htmlFor="image_upload" className="upload-label">
            <CloudUploadOutlinedIcon style={{ fontSize: 40, color: "#333" }} />
            <p>Upload New Images</p>
          </label>
          <input
            id="image_upload"
            type="file"
            accept=".jpeg, .png, .jpg"
            multiple
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
        </div>

        {/* Display existing images */}
        {formData.images.length === 0 && <h4>No Existing Images</h4>}
        {formData.images.length > 0 && (
          <div className="image-previews">
            <h4>Existing Images</h4>
            <div className="image-list">
              {formData.images.map((url, index) => (
                <div key={index} className="image-item">
                  <img height="100px" src={url} alt={`existing-${index}`} />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(index, "existing")}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Display newly uploaded images */}
        {formData.newImages.length > 0 && (
          <div className="image-previews">
            <h4>Newly Uploaded Images</h4>
            <div className="image-list">
              {formData.newImages.map((file, index) => (
                <div key={index} className="image-item">
                  <img
                    height="100px"
                    src={URL.createObjectURL(file)}
                    alt={`new-${index}`}
                  />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(index, "new")}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="btn">
          <button type="submit" disabled={isUploading}>
            {isUploading ? "Updating..." : "Update"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Edit;
