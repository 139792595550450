import React from "react";
import "../Styles/About.scss";
import { Main_Container } from "../Containers/Main_Container";
import { Footer } from "./Footer";

export const About = () => {
  const cardData = [
    {
      imageSrc:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBMBpN7RlaPAXoV3ibURXzs8mEgB3AXoMGqA&s",
      title: "Person 1",
      text: "This is the first card.",
      link: "#",
    },
    {
      imageSrc:
        "https://png.pngtree.com/png-vector/20231014/ourmid/pngtree-businessman-and-mobile-phone-png-image_10159319.png",
      title: "Person 2",
      text: "This is the second card.",
      link: "#",
    },
    {
      imageSrc:
        "https://slidesbase.com/wp-content/uploads/2023/06/01-young-successful-asian-businessman-in-office-stock-photo_stock_photo_slidesbase_com-1.jpeg",
      title: "Person 3",
      text: "This is the third card.",
      link: "#",
    },
    // Add more cards as needed
  ];

  return (
    <div className="about">
      <Main_Container />
      <div className="content">
        <h2>ABOUT</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
          corrupti inventore distinctio quod unde, at omnis aspernatur
          perspiciatis repellat modi? Non in dolores odit quos assumenda,
          aperiam doloremque fugit mollitia!
        </p>
      </div>
      <div className="card_container">
        {cardData.map((card, index) => (
          <div className="card" key={index} style={{ width: "14rem" }}>
            <img
              src={card.imageSrc}
              className="card-img-top"
              alt={card.title}
            />
            <div className="card-body">
              <h5 className="card-title">{card.title}</h5>
              <p className="card-text">{card.text}</p>
              <a href={card.link} className="btn btn-primary">
                Contact
              </a>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};
