import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Box } from "@mui/material";

const CategoryFetcher = ({ selectedType, onCategoryClick }) => {
  const serverUrl = process.env.REACT_APP_SERVER_URL; // ${serverUrl}
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (selectedType) {
      console.log(selectedType);
      axios
        .get(`${serverUrl}/api/categories/${selectedType}`)
        .then((response) => {
          // console.log("API response:", response.data); // Check API response
          setCategories(response.data); // Adjust this based on response structure
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    } else {
      console.log("No selected type");
    }
  }, [selectedType]);

  return (
    <Box>
      {categories.length > 0 ? (
        categories.map((category, index) => (
          <Button
            key={index}
            variant="outlined"
            style={{ margin: 4 }}
            onClick={() => onCategoryClick(category)}
          >
            {category}
          </Button>
        ))
      ) : (
        <p>Waiting for categories available for this type.</p>
      )}
    </Box>
  );
};

export default CategoryFetcher;
