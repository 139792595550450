import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PhoneIcon from "@mui/icons-material/Phone";
import { Carousel } from "react-responsive-carousel"; // Import Carousel for image slider
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styling
import "../Styles/ProductDetails.scss";
import { Footer } from "../Components/Footer";

const ProductDetails = () => {
  const serverUrl = process.env.REACT_APP_SERVER_URL; // ${serverUrl}
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `${serverUrl}/api/productDetails/${id}`
        );
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProductDetails();
  }, [id]);
  const handleButtonClick = () => {
    window.location.href = "tel:+91 8903304612"; // Replace with actual phone number
  };

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  if (!product) {
    return <div className="loading">🔄 Loading product details...</div>;
  }

  return (
    <div className="main_cintainer">
      <div className="product-details-container">
        {/* Product Image and Thumbnails */}
        <div className="product-image-carousel">
          <Carousel showThumbs showIndicators={false} showStatus={false}>
            {product.imageUrls?.map((image, index) => (
              <div key={index} className="carousel-image">
                <img src={image} alt={`Product Image ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>

        {/* Product Information Section */}
        <div className="product-details-content">
          <h1>{product.productTitle} 💎</h1>
          <p className="product-brand">
            <strong>Brand:</strong> {product.brandName}
          </p>
          <p>
            <strong>Model Number:</strong> {product.modelNo}
          </p>
          <p>
            <strong>Type:</strong> {product.types}
          </p>
          <p>
            <strong>Category:</strong> {product.categories}
          </p>
          <p>
            <strong>Size:</strong> {product.size}
          </p>
          <p className="product-description">
            <strong>Description:</strong> {product.productDescription}
          </p>
          <p className="product-price">
            <strong>Price per SqFt:</strong>{" "}
            <span>₹{product.pricePerSqFt}</span>
          </p>
        </div>
      </div>

      {product.youtubeLink && (
        <div className="product-video-wrapper">
          <h2 className="video-title">Product Video</h2>
          <div className="video-container">
            <iframe
              className="frame"
              width="100%"
              height="480"
              src={`https://www.youtube.com/embed/${product.youtubeLink}`} //GVUqZC7lNiw?si=mRHdrvqeG4npOCvL   ${product.youtubeLink}
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}

      <div className="buttons-container">
        <button onClick={handleButtonClick} className="btn contact-btn">
          <PhoneIcon /> +91 8903304612
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default ProductDetails;
