import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  useMemo,
} from "react";
import axios from "axios";
import { Main_Container } from "../Containers/Main_Container";
import { Carousel } from "react-responsive-carousel";
import Grid from "@mui/material/Grid";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import "../Styles/Home.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Footer } from "../Components/Footer";
import { PriceRangeContext } from "../context/PriceRangeContext";
import { useNavigate } from "react-router-dom";
import img1 from "../docs/photo.jpg";
import img2 from "../docs/synk.jpg";
import img3 from "../docs/ima.jpg";

export const Home = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]); // Original product list for the current category
  const [totalProducts, setTotalProducts] = useState([]); // Complete product list
  const [searchResults, setSearchResults] = useState([]); // Store search results
  const [filteredProducts, setFilteredProducts] = useState([]); // Store filtered products for display
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [loading, setLoading] = useState(true);
  const [lastAction, setLastAction] = useState(""); // Track the last action
  const showResultsRef = useRef(null);
  const { range, version } = useContext(PriceRangeContext); // Use price range from context
  const navigate = useNavigate();
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const fetchData = useCallback(async () => {
    setLoading(true); // Indicate loading state

    try {
      // Fetch both product and category data concurrently
      const [productResponse, categoriesResponse] = await Promise.all([
        axios.get(`${serverUrl}/api/data`),
        axios.get(`${serverUrl}/api/random-categories`), //  ${serverUrl}
      ]);

      // Process product data
      const allProducts = productResponse.data;
      setTotalProducts(allProducts);
      setProducts(allProducts.slice(0, 6)); // Set initial products to the first 8
      setFilteredProducts(allProducts.slice(0, 6)); // Initial filter is the same as prod
      console.log(filteredProducts);
      // Process category data
      const allCategories = [
        {
          _id: "All",
          randomImage:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWjJqX9NIdB3kPLusMIJm3hp5O8PUnTeIbK5b3rCl7oAvDGmz_27mPCHRufswDjgwegTQ&usqp=CAU",
        },
        ...categoriesResponse.data, // Append fetched categories
      ];

      setCategories(allCategories);
    } catch (error) {
      console.error("Error fetching data:", error); // Log errors if any
    } finally {
      setLoading(false); // End loading state
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Fetch products based on category
  const fetchProducts = useCallback(async (category) => {
    setLoading(true);
    try {
      const response =
        category === "All"
          ? await axios.get(`${serverUrl}/api/data`)
          : await axios.get(`${serverUrl}/api/products/${category}`);

      setProducts(response.data);
      setSearchResults([]); // Reset search results when changing categories
      setFilteredProducts(response.data); // Display fetched products
      console.log(filteredProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts([]);
      setFilteredProducts([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const carouselImages = [img1, img2, img3];

  const loadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh", // Adjust as necessary
  };

  // Handle category click
  const handleCategoryClick = useCallback(
    (category) => {
      setSelectedCategory(category);
      setLastAction("category"); // Mark last action as category selection
      fetchProducts(category);
    },
    [fetchProducts]
  );

  // Handle search functionality
  const handleSearch = useCallback(
    (searchTerm) => {
      let filtered = totalProducts;

      if (searchTerm.trim() !== "") {
        const lowercasedSearchTerm = searchTerm.toLowerCase();

        filtered = filtered.filter((product) => {
          const productTitle = product.productTitle?.toLowerCase() || "";
          const productTypes = product.types?.toLowerCase() || "";
          const productCategory = product.category?.toLowerCase() || "";

          return (
            productTitle.includes(lowercasedSearchTerm) ||
            productTypes.includes(lowercasedSearchTerm) ||
            productCategory.includes(lowercasedSearchTerm)
          );
        });
      }

      setSearchResults(filtered); // Store the filtered search results
      setFilteredProducts(filtered); // Display the search results
      console.log(filteredProducts);
      setSelectedCategory("All"); // Reset category to "All"
      setLastAction("search"); // Mark last action as search

      // Scroll to results area
      showResultsRef.current?.scrollIntoView({ behavior: "smooth" });
    },
    [totalProducts]
  );

  // Apply filter based on price range
  useEffect(() => {
    if (loading) return; // Don't apply filter if still loading

    let filtered;

    if (lastAction === "category") {
      // Filter products based on the selected category
      filtered = products.filter(
        (product) =>
          product.pricePerSqFt >= range[0] && product.pricePerSqFt <= range[1]
      );
    } else if (lastAction === "search") {
      // Filter the search results based on the price range
      filtered = searchResults.filter(
        (product) =>
          product.pricePerSqFt >= range[0] && product.pricePerSqFt <= range[1]
      );
    } else {
      // Filter total products in case no specific action was last performed
      filtered = totalProducts.filter(
        (product) =>
          product.pricePerSqFt >= range[0] && product.pricePerSqFt <= range[1]
      );
    }

    setFilteredProducts(filtered);
    console.log(filteredProducts);
  }, [range, version, searchResults, lastAction]);
  // }, [range, products, searchResults, lastAction, totalProducts, loading]);

  useEffect(() => {
    console.log(filteredProducts);
  }, []);
  return (
    <div className="home">
      <Main_Container onSearch={handleSearch} />
      <center>
        <div className="mari">
          <i className="fa-solid fa-bullhorn fa-xs"></i>
          <marquee className="marq" width="100%" direction="left" height="20px">
            <a href="">
              Explore Our Extensive Collection of Premium Tiles and Granites
            </a>
            <img
              src="https://www.francisxavier.ac.in/cs-content/themes/fxec/images/new-icon.gif"
              alt="New"
            />{" "}
            &nbsp; &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp; &nbsp;
            <a href="">
              Elevate Your Spaces with Unmatched Quality and Design.
            </a>
            <img
              src="https://www.francisxavier.ac.in/cs-content/themes/fxec/images/new-icon.gif"
              alt="New"
            />{" "}
            &nbsp; &nbsp; &nbsp; &nbsp; | &nbsp; &nbsp; &nbsp; &nbsp;
            <a href="">
              Transform Your Home with Our Exclusive Range of Tiles and Granites
            </a>
            <img
              src="https://www.francisxavier.ac.in/cs-content/themes/fxec/images/new-icon.gif"
              alt="New"
            />
          </marquee>
        </div>
      </center>
      <div className="carousel_div" style={{ maxWidth: "100%" }}>
        <Carousel
          autoPlay
          interval={3000}
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          stopOnHover={false}
          transitionTime={500}
          swipeable
          emulateTouch
          showArrows={false}
        >
          {carouselImages.map((src, index) => (
            <div key={index}>
              <img
                className="carousel_image"
                src={src}
                alt={`Image ${index + 1}`}
              />
            </div>
          ))}
        </Carousel>
      </div>
      <div className="adv">
        <center>
          <h2 ref={showResultsRef}>
            Our <span>Collections</span>
          </h2>
          <div className="sp"></div>
        </center>
        <div className="categories">
          {categories.length > 0 ? (
            categories.map((category) => (
              <div
                className={`outer ${
                  selectedCategory === category._id ? "selected" : ""
                }`}
                key={category._id}
                onMouseEnter={() => setHoveredCategory(category._id)}
                onMouseLeave={() => setHoveredCategory(null)}
                onClick={() => handleCategoryClick(category._id)}
              >
                <div className="inner">
                  <img src={category.randomImage} alt={category._id} />
                  <span>{category._id}</span>
                </div>

                {/* Show tooltip only when this category is hovered */}
                {hoveredCategory === category._id && (
                  <div className="tooltip-box">{category._id}</div>
                )}
              </div>
            ))
          ) : (
            <center>{/* <p>No categories available.</p> */}</center>
          )}
        </div>
        <div className="show_results">
          {loading ? (
            <Grid item xs={12} style={loadingStyle}>
              <CircularProgress />
            </Grid>
          ) : filteredProducts.length > 0 ? (
            filteredProducts.map((product, index) => (
              <Card
                onClick={() => navigate(`/productDetails/${product._id}`)}
                key={index}
                className="card"
              >
                <Box>
                  <Carousel showThumbs={false}>
                    {product.imageUrls.map((url, i) => (
                      <div key={i}>
                        <img
                          src={url}
                          alt={`Product Image ${i + 1}`}
                          className="card-image"
                        />
                      </div>
                    ))}
                  </Carousel>
                </Box>
                <CardContent className="card_content">
                  <Typography gutterBottom variant="h6">
                    {product.productTitle}
                  </Typography>
                  <Typography variant="h6" color="primary">
                    ₹{product.pricePerSqFt}
                  </Typography>
                </CardContent>
                <CardActions className="card-actions">
                  <Button size="small" className="card-button">
                    View Details
                  </Button>
                </CardActions>
              </Card>
            ))
          ) : (
            !loading && (
              <Grid item xs={12}>
                <center>
                  <p>No products found.</p>
                </center>
              </Grid>
            )
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
